<template>
  <section>
    <!--        <dash-header/>-->
    <dash-content/>
  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBreadcrumb,
  BButton,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import DashHeader from './dashComp/dashHeader.vue'
import DashContent from './dashComp/dashContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    flatPickr,
    BBreadcrumb,
    BButton,
    DashContent,
    DashHeader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dateDefault: null,
    }
  },
  computed: {
    isVardiya() {
      this.$store.getters.returnVardiya
    },
  },
  beforeCreate() {
    this.$store.commit('clearRoom')
    this.$store.dispatch('dashIn', { clearRoom: true }, { root: true })
  },
}
</script>

<style scoped></style>
