<template>
  <b-container
      class="mb-3 dash-container"
      fluid
  >
    <hr class="mt-3">
    <b-spinner
        v-if="this.$store.state.auth.room == ''"
        variant="primary"
        class="spinners"
    />
    <dash-modals
        :oda_index="odaKodNumarasi"
        :modal_durum="modalDurum"
        :durum="durum"
    />
    <b-row class="mt-2">
      <b-col
          v-for="(oda, index) in $store.getters.odalarReturn"
          :key="index"
          class="col-xxl-1 col-lll-2 col-lll-3 col-lll-4 col-lll-5 col-lll-6 col-lll-7 col-lg-2 col-md-6 col-sm-6 col-6 col-xs-6"
      >
        <b-card no-body>
          <b-list-group-item
              class="card-header"
              :style="{
              backgroundColor:
                oda.odaBilgileri.odaRenk == 1
                  ? $store.getters.bgPrimary
                  : hucreRenk && oda.odaBilgileri.odaRenk == 2
                    ? $store.getters.bgSuccess
                    : hucreRenk && oda.odaBilgileri.odaRenk == 3
                      ? $store.getters.bgInfo
                      : hucreRenk && oda.odaBilgileri.odaRenk == 4
                        ? $store.getters.bgDanger
                        : hucreRenk && oda.odaBilgileri.odaRenk == 5
                          ? $store.getters.bgWarning
                          : hucreRenk,
            }"
          >
            <b-row>
              <b-col cols="2">
                <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="dropPadding"
                    variant="white"
                    split-variant="white"
                    no-caret
                    size="sm"
                    style="z-index: 600 !important"
                >
                  <template #button-content>
                    <feather-icon
                        icon="MenuIcon"
                        class="text-white"
                    />
                  </template>
                  <b-dropdown-item
                      v-if="oda.odaBilgileri.odaStatus == 2"
                      @click="
                      $store.dispatch('temizOda', oda.odaBilgileri.odaKodu)
                    "
                  >
                    Temiz Oda
                  </b-dropdown-item>
                  <b-dropdown-item
                      v-b-modal="'oda-durumu'"
                      @click="modalAcilcak(oda.odaBilgileri)"
                  >
                    Oda Durum
                  </b-dropdown-item>
                  <b-dropdown-item
                      v-b-modal="'acenta'"
                      @click="modalAcilcak(oda.odaBilgileri)"
                  >
                    Acenta
                  </b-dropdown-item>
                  <b-dropdown-item
                      :to="'/yeniRezervasyon/' + oda.odaBilgileri.odaKodu"
                  >Check In
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col cols="9">
                <p class="room-text dropPadding">
                  {{ oda.odaBilgileri.odaKodu }}
                  ({{ odaTipKontrol(oda.odaBilgileri.odaTipi) }})
                  <small style="font-weight: bolder" v-b-tooltip.hover="'House Keeping'"
                  >
                    {{ oda.odaBilgileri.odaStatus == 2 ? '(HK)' : '' }}
                  </small>
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item
              droppable="true"
              class="cardFooter"
              :style="{
              backgroundColor: !oda.rezervasyonBilgileri
                ? oda['odaBilgileri']['odaStatus'] == 1
                  ? hucreRenk
                  : hucreRenk && oda['odaBilgileri']['odaStatus'] == 2
                    ? $store.getters.kirliOda
                    : hucreRenk && oda['odaBilgileri']['odaStatus'] == 3
                      ? $store.getters.arizaliOda
                      : hucreRenk && oda['odaBilgileri']['odaStatus'] == 4
                        ? $store.getters.kullanimDisi
                        : hucreRenk
                : '',
            }"
              @drop="onDropp($event, oda)"
              @dragenter.prevent
              @dragover.prevent
          >
            <div
                v-if="oda['odaBilgileri']['acentaRenk']"
                v-show="$store.state.auth.params.listType == 0"
                class="dot"
                :style="{
                backgroundColor: oda['odaBilgileri']['acentaRenk'],
              }"
            />
            <b-carousel
                id="carousel-caption"
                :controls="oda.rezervasyonCount > 1"
            >
              <b-row
                  v-if="!oda.rezervasyonBilgileri"
                  v-show="
                  oda.odaBilgileri.odaStatus != 4 &&
                    oda.odaBilgileri.odaStatus != 3
                "
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="bgPrimary"
                    class="btn-icon cardFooter-button pull-up"
                    size="sm"
                    :to="'/yeniRezervasyon/' + oda.odaBilgileri.odaKodu"
                >
                  <feather-icon icon="PlusIcon"/>
                </b-button>
              </b-row>
              <b-carousel-slide
                  v-for="(rezervasyon, index) in oda.rezervasyonBilgileri"
                  :key="index"
                  :v-if="oda.rezervasyonCount > 1"
                  class="card-img-overlay"
                  img-blank
              >
                <div
                    v-for="(konuk, index) in rezervasyon"
                    :key="index"
                    class="carouselInCard"
                    :style="{
                    backgroundColor:
                      konuk['odaKapatma'] == 1
                        ? $store.getters.odaKapatma
                        : parseInt(oda['odaBilgileri']['odaYatak']) +
                          parseInt(oda['odaBilgileri']['odaEkYatak']) ==
                          Object.keys(rezervasyon).length
                          ? $store.getters.fullOda
                          : oda.odaBilgileri.odaStatus == 0
                            ? hucreRenk
                            : oda.odaBilgileri.odaStatus == 1
                              ? hucreRenk
                              : oda.odaBilgileri.odaStatus == 2
                                ? $store.getters.kirliOda
                                : oda.odaBilgileri.odaStatus == 3
                                  ? $store.getters.arizaliOda
                                  : oda.odaBilgileri.odaStatus == 4
                                    ? $store.getters.kullanimDisi
                                    : hucreRenk,
                  }"
                >
                  <div
                      :class="
                      Object.keys(rezervasyon).length >= 5
                        ? 'scrollable'
                        : 'scrollable'
                    "
                  >
                    <div v-if="$store.state.auth.params.listType == 1">
                      <b-row
                          v-for="(person, index) in rezervasyon"
                          :key="index"
                          style="z-index: 900 !important"
                          class="text-center justify-content-center list-row"
                      >
                        <b-badge
                            draggable="true"
                            href="#"
                            class="d-block list-variant konukIsim handle"
                            variant="light-dark"
                            :to="'/rezervasyon/' + person.rezervasyonKodu"
                            :style="{
                            backgroundColor: oda.odaBilgileri.acentaRenk
                              ? oda.odaBilgileri.acentaRenk + '99'
                              : 'rgb(0 0 0 / 40%)',
                            'z-index': '900!important',
                          }"
                            @dragstart="startDragg($event, person, index, oda)"
                        >
                          {{ person.konukAdi }}
                          {{ person.konukSoyadi ? person.konukSoyadi.charAt(0) : ' ' + '.' }}
                          <feather-icon
                              v-if="person.onlineRezervasyon == 1"
                              icon="ChromeIcon"
                              class="mr-25 text-warning"
                          />
                          <feather-icon
                              v-if="
                              person.cikisTarih == $store.state.auth.workDay
                            "
                              icon="LogOutIcon"
                              class="mr-25"
                              style="color: #03ccff"
                          />
                          <feather-icon
                              v-if="person.onRezervasyon == 1"
                              icon="LoaderIcon"
                              class="mr-25 text-danger"
                          />
                        </b-badge>
                      </b-row>
                    </div>
                  </div>
                  <b-avatar-group
                      v-if="$store.state.auth.params.listType == 0"
                      size="32px"
                  >
                    <b-avatar
                        v-for="(person, index) in rezervasyon"
                        :key="index"
                        v-b-tooltip.hover
                        :title="person.konukAdi + ' ' + person.konukSoyadi"
                        style="display: table-cell"
                        :to="'/rezervasyon/' + person.rezervasyonKodu"
                        class="pull-up"
                        :text="person.konukAdi ?
                        person.konukAdi.charAt(0) : ' ' +
                          '.' +
                          person.konukSoyadi ? person.konukSoyadi.charAt(0) : ' ' +
                          '.'
                      "
                        badge=""
                        :badge-variant="
                        person.cikisTarih == $store.state.auth.workDay
                          ? 'bgDanger'
                          : 'bgSuccess'
                      "
                        :style="{
                        boxShadow:
                          person.onlineRezervasyon == 1
                            ? '0 0 0 3px ' +
                              $store.state.auth.params.avatar
                                .onlineRezervasyon +
                              ', inset 0 0 0 1px rgb(34 41 47 / 17%)'
                            : $store.state.auth.params.avatar.standart &&
                              person.onRezervasyon == 1
                              ? '0 0 0 3px ' +
                                $store.state.auth.params.avatar.onRezervasyon +
                                ', inset 0 0 0 1px rgb(34 41 47 / 17%)'
                              : $store.state.auth.params.avatar.standart,
                        backgroundColor:
                          person.cinsiyet == 1
                            ? $store.getters.bgInfo
                            : $store.getters.bgDanger,
                      }"
                    />
                  </b-avatar-group>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </b-list-group-item>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BBadge,
  BModal,
  VBModal,
  BAvatar,
  BAvatarGroup,
  BSpinner,
  BCarousel,
  BCarouselSlide,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardText,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BFormInput,
  BInputGroup,
  VBTooltip,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DashModals from './dashMultipleModals.vue'

export default {
  components: {
    draggable,
    VuePerfectScrollbar,
    BImg,
    DashModals,
    BModal,
    BContainer,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BAvatarGroup,
    BSpinner,
    BCarousel,
    BCarouselSlide,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardText,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormInput,
    BInputGroup,
    BButton,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      conflict: 0,
      dragRezNo: '',
      dragGuestID: 0,
      dragOdaKodu: 0,
      rezCount: 2,
      rezControl: true,
      acentaColor: '#FF74B1',
      dateDefault: null,
      odaKapatma: false,
      hucreRenk: '#d6d6d6',
      odaKodNumarasi: null,
      durum: null,
      modalDurum: false,
    }
  },
  computed: {
    listType() {
      return localStorage.getItem('otelListType') == 0
    },
  },
  beforeDestroy() {
    this.$store.commit('clearRoom')
  },
  created() {
  },
  methods: {
    onDropp(event, oda) {
      this.dragOdaKodu = oda.odaBilgileri.odaKodu
      if (this.dragGuestID && this.conflict != this.dragOdaKodu) {
        this.$store
            .dispatch('dragOdaChange', {
              rezervsayonNo: this.dragRezNo,
              guestID: this.dragGuestID,
              odaKodu: this.dragOdaKodu,
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              this.$store.dispatch('dashIn', { clearRoom: false })
              this.dragRezNo = ''
              this.dragGuestID = 0
              this.dragOdaKodu = 0
              this.conflict = 0
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            })
            .catch(err => {
              this.dragRezNo = ''
              this.dragGuestID = 0
              this.dragOdaKodu = 0
              this.conflict = 0
              const mesaj = this.$store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      }
    },
    startDragg(event, item, guestID, oda) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      this.conflict = oda.odaBilgileri.odaKodu
      const rezNo = item.rezervasyonKodu
      this.dragRezNo = rezNo
      this.dragGuestID = guestID
    },
    odaDurumStatus(odaDurumInput) {
      switch (odaDurumInput) {
        case 'Müsait':
          this.odaStatus = 1
          break
        case 'Kirli':
          this.odaStatus = 2
          break
        case 'Arızalı':
          this.odaStatus = 3
          break
        case 'Kullanım Dışı':
          this.odaStatus = 4
          break
        default:
          break
      }
    },
    odaTipKontrol(odaTipi) {
      return odaTipi.slice(0, 2)
          .toUpperCase()
    },
    modalAcilcak(index) {
      this.modalDurum = true
      this.odaKodNumarasi = index
    },
  },
}
</script>
<style scoped>
@media only screen and (max-width: 6000px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 600px !important;
    transform: translate(-125px, 40px) !important;
  }
}

@media only screen and (max-width: 4300px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 400px !important;
    transform: translate(-75px, 40px) !important;
  }
}

@media only screen and (max-width: 4000px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 350px !important;
    transform: translate(-55px, 40px) !important;
  }
}

@media only screen and (max-width: 3800px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 330px !important;
    transform: translate(-49px, 40px) !important;
  }
}

@media only screen and (max-width: 3600px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 290px !important;
    transform: translate(-42px, 40px) !important;
  }
}

@media only screen and (max-width: 3300px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 270px !important;
    transform: translate(-40px, 40px) !important;
  }
}

@media only screen and (max-width: 2900px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-30px, 40px) !important;
  }
}

@media only screen and (max-width: 2800px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-33px, 40px) !important;
  }
}

@media only screen and (max-width: 2680px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-39px, 40px) !important;
  }
}

@media only screen and (max-width: 2620px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-40px, 40px) !important;
  }
}

@media only screen and (max-width: 2550px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-41px, 40px) !important;
  }
}

@media only screen and (max-width: 2450px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-42px, 40px) !important;
  }
}

@media only screen and (max-width: 2400px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-45px, 40px) !important;
  }
}

@media only screen and (max-width: 2360px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-46px, 40px) !important;
  }
}

@media only screen and (max-width: 2330px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-49px, 40px) !important;
  }
}

@media only screen and (max-width: 2280px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-50px, 40px) !important;
  }
}

@media only screen and (max-width: 2250px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-50px, 40px) !important;
  }
}

@media only screen and (max-width: 2230px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-51px, 40px) !important;
  }
}

@media only screen and (max-width: 2190px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-51px, 40px) !important;
  }
}

@media only screen and (max-width: 2150px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-51px, 40px) !important;
  }
}

@media only screen and (max-width: 2120px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-52px, 40px) !important;
  }
}

@media only screen and (max-width: 2100px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-54px, 40px) !important;
  }
}

@media only screen and (max-width: 2080px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-55px, 40px) !important;
  }
}

@media only screen and (max-width: 2060px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-55px, 40px) !important;
  }
}

@media only screen and (max-width: 2040px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-56px, 40px) !important;
  }
}

@media only screen and (max-width: 2020px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-57px, 40px) !important;
  }
}

@media only screen and (max-width: 2000px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-58px, 40px) !important;
  }
}

@media only screen and (max-width: 1990px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-58px, 40px) !important;
  }
}

@media only screen and (max-width: 1980px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-58px, 40px) !important;
  }
}

@media only screen and (max-width: 1970px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-58px, 40px) !important;
  }
}

@media only screen and (max-width: 1960px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-58px, 40px) !important;
  }
}

@media only screen and (max-width: 1950px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-59px, 40px) !important;
  }
}

@media only screen and (max-width: 1940px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-60px, 40px) !important;
  }
}

@media only screen and (max-width: 1930px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-59px, 40px) !important;
  }
}

@media only screen and (max-width: 1920px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-61px, 40px) !important;
  }
}

@media only screen and (max-width: 1910px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-61px, 40px) !important;
  }
}

@media only screen and (max-width: 1900px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-60px, 40px) !important;
  }
}

@media only screen and (max-width: 1890px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-61px, 40px) !important;
  }
}

@media only screen and (max-width: 1880px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-62px, 40px) !important;
  }
}

@media only screen and (max-width: 1870px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-61px, 40px) !important;
  }
}

@media only screen and (max-width: 1860px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-62px, 40px) !important;
  }
}

@media only screen and (max-width: 1850px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-62px, 40px) !important;
  }
}

@media only screen and (max-width: 1840px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-62px, 40px) !important;
  }
}

@media only screen and (max-width: 1830px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-62px, 40px) !important;
  }
}

@media only screen and (max-width: 1820px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-62px, 40px) !important;
  }
}

@media only screen and (max-width: 1810px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-62px, 40px) !important;
  }
}

@media only screen and (max-width: 1800px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-63px, 40px) !important;
  }
}

@media only screen and (max-width: 1790px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-63px, 40px) !important;
  }
}

@media only screen and (max-width: 1780px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-64px, 40px) !important;
  }
}

@media only screen and (max-width: 1770px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-64px, 40px) !important;
  }
}

@media only screen and (max-width: 1760px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-63px, 40px) !important;
  }
}

@media only screen and (max-width: 1750px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-64px, 40px) !important;
  }
}

@media only screen and (max-width: 1740px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-63px, 40px) !important;
  }
}

@media only screen and (max-width: 1730px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-64px, 40px) !important;
  }
}

@media only screen and (max-width: 1720px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-66px, 40px) !important;
  }
}

@media only screen and (max-width: 1710px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-72px, 40px) !important;
  }
}

@media only screen and (max-width: 1700px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-67px, 40px) !important;
  }
}

@media only screen and (max-width: 1690px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-68px, 40px) !important;
  }
}

@media only screen and (max-width: 1680px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-66px, 40px) !important;
  }
}

@media only screen and (max-width: 1670px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-68px, 40px) !important;
  }
}

@media only screen and (max-width: 1640px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-64px, 40px) !important;
  }
}

@media only screen and (max-width: 1630px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-65px, 40px) !important;
  }
}

@media only screen and (max-width: 1620px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-68px, 40px) !important;
  }
}

@media only screen and (max-width: 1600px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 255px !important;
    transform: translate(-82px, 40px) !important;
  }
}

/* son responsive değişiklik */
@media only screen and (max-width: 1560px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 250px !important;
    transform: translate(-81px, 40px) !important;
  }
}

@media only screen and (max-width: 1530px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 240px !important;
    transform: translate(-76px, 40px) !important;
  }
}

@media only screen and (max-width: 1500px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 240px !important;
    transform: translate(-75px, 40px) !important;
  }
}

@media only screen and (max-width: 1490px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 240px !important;
    transform: translate(-75px, 40px) !important;
  }
}

@media only screen and (max-width: 1480px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 240px !important;
    transform: translate(-75px, 40px) !important;
  }
}

@media only screen and (max-width: 1460px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 240px !important;
    transform: translate(-76px, 40px) !important;
  }
}

@media only screen and (max-width: 1450px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 230px !important;
    transform: translate(-74px, 40px) !important;
  }
}

@media only screen and (max-width: 1440px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 230px !important;
    transform: translate(-34px, 40px) !important;
  }
}

@media only screen and (max-width: 1430px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-34px, 40px) !important;
  }
}

@media only screen and (max-width: 1425px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-35px, 40px) !important;
  }
}

@media only screen and (max-width: 1420px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-34px, 40px) !important;
  }
}

@media only screen and (max-width: 1270px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-40px, 40px) !important;
  }
}

@media only screen and (max-width: 1190px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-45px, 40px) !important;
  }
}

@media only screen and (max-width: 1110px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-50px, 40px) !important;
  }
}

@media only screen and (max-width: 1000px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-55px, 40px) !important;
  }
}

@media only screen and (max-width: 850px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-60px, 40px) !important;
  }
}

@media only screen and (max-width: 800px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-45px, 40px) !important;
  }
}

@media only screen and (max-width: 750px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 223px !important;
    transform: translate(-32px, 40px) !important;
  }
}

@media only screen and (max-width: 450px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 250px !important;
    transform: translate(-55px, 40px) !important;
  }
}

@media only screen and (max-width: 720px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 250px !important;
    transform: translate(-65px, 40px) !important;
  }
}

@media only screen and (max-width: 550px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 250px !important;
    transform: translate(-37px, 40px) !important;
  }
}

@media only screen and (max-width: 500px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 250px !important;
    transform: translate(-47px, 40px) !important;
  }
}

@media only screen and (max-width: 350px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 250px !important;
    transform: translate(-70px, 40px) !important;
  }
}

@media only screen and (max-width: 300px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 270px !important;
    transform: translate(-160px, 40px) !important;
  }
}

@media only screen and (max-width: 740px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 270px !important;
    transform: translate(-70px, 40px) !important;
  }
}

.dropdown {
  margin-right: 130px !important;
}

@media only screen and (min-width: 1560px) {
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}

@media only screen and (max-width: 1440px) {
  .col-lll-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}

@media only screen and (max-width: 800px) {
  .col-lll-3 {
    flex: 0 0 25%;
    width: 25.66666667%;
  }
}

@media only screen and (max-width: 700px) {
  .col-lll-4 {
    flex: 0 0 33.333333%;
    width: 40.66666667%;
  }
}

@media only screen and (max-width: 600px) {
  .col-lll-5 {
    flex: 0 0 33.333333%;
    width: 50.66666667%;
  }
}

@media only screen and (max-width: 550px) {
  .col-lll-6 {
    flex: 0 0 50%;
    width: 70.66666667%;
  }
}

@media only screen and (max-width: 300px) {
  .col-lll-7 {
    flex: 0 0 100%;
    width: 70.66666667%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 600px !important;
    transform: translate(-150px, 40px) !important;
  }
}

@media only screen and (max-width: 770px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 600px !important;
    transform: translate(-180px, 40px) !important;
  }
}

@media only screen and (max-width: 625px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 600px !important;
    transform: translate(-195px, 40px) !important;
  }
}

@media only screen and (max-width: 550px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 600px !important;
    transform: translate(-210px, 40px) !important;
  }
}

@media only screen and (max-width: 550px) {
  .carouselInCard {
    min-height: 75px !important;
    min-width: 600px !important;
    transform: translate(-220px, 40px) !important;
  }
}

.carouselInCard {
  min-height: 75px !important;
  min-width: 123px;
  transform: translate(-20px, 40px);
}

.carousel-item {
  border-radius: 0;
}

.avatarCustom {
  margin-top: 15px;
  margin: 0 auto;
  margin-top: 15px;
  padding: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  transition: 300ms !important;
}

.list-group-item {
  padding: 0 !important;
  color: white;
}

.card {
  font-size: 12.6px;
  margin-bottom: 0.6rem;
}

.cardFooter {
  min-height: 75px;
  min-width: 123px;
  border: none !important;
}

.room-text {
  white-space: nowrap;
  color: white;
  margin-left: 8px !important;
  margin-top: 4px !important;
}

.room-icon {
  color: red;
}

.card-header {
  max-height: 30px;
  min-width: 123px;
  border: none !important;
}

.room-icon {
  width: 100px;
  margin-left: -15px;
}

.cardFooter-button {
  margin: 0 auto;
  margin-top: 20px;
}

.card-img-overlay {
  padding: 1px !important;
  max-height: 75px !important;
  min-height: 75px !important;
}

.avatarCustomize {
  margin-top: 1.2rem !important;
}

.b-avatar-group-inner {
  margin-top: -1.1rem !important;
}

.list-variant {
  color: white !important;
}

.dropPadding {
  padding-left: 8px;
}

.list-row {
  margin-top: 2px;
}

.konukIsim {
  font-size: 100%;
}
</style>
<style scoped>
[dir="ltr"] .col-xl,
[dir="ltr"] .col-xl-auto,
[dir="ltr"] .col-xl-12,
[dir="ltr"] .col-xl-11,
[dir="ltr"] .col-xl-10,
[dir="ltr"] .col-xl-9,
[dir="ltr"] .col-xl-8,
[dir="ltr"] .col-xl-7,
[dir="ltr"] .col-xl-6,
[dir="ltr"] .col-xl-5,
[dir="ltr"] .col-xl-4,
[dir="ltr"] .col-xl-3,
[dir="ltr"] .col-xl-2,
[dir="ltr"] .col-xl-1,
[dir="ltr"] .col-lg,
[dir="ltr"] .col-lg-auto,
[dir="ltr"] .col-lg-12,
[dir="ltr"] .col-lg-11,
[dir="ltr"] .col-lg-10,
[dir="ltr"] .col-lg-9,
[dir="ltr"] .col-lg-8,
[dir="ltr"] .col-lg-7,
[dir="ltr"] .col-lg-6,
[dir="ltr"] .col-lg-5,
[dir="ltr"] .col-lg-4,
[dir="ltr"] .col-lg-3,
[dir="ltr"] .col-lg-2,
[dir="ltr"] .col-lg-1,
[dir="ltr"] .col-md,
[dir="ltr"] .col-md-auto,
[dir="ltr"] .col-md-12,
[dir="ltr"] .col-md-11,
[dir="ltr"] .col-md-10,
[dir="ltr"] .col-md-9,
[dir="ltr"] .col-md-8,
[dir="ltr"] .col-md-7,
[dir="ltr"] .col-md-6,
[dir="ltr"] .col-md-5,
[dir="ltr"] .col-md-4,
[dir="ltr"] .col-md-3,
[dir="ltr"] .col-md-2,
[dir="ltr"] .col-md-1,
[dir="ltr"] .col-sm,
[dir="ltr"] .col-sm-auto,
[dir="ltr"] .col-sm-12,
[dir="ltr"] .col-sm-11,
[dir="ltr"] .col-sm-10,
[dir="ltr"] .col-sm-9,
[dir="ltr"] .col-sm-8,
[dir="ltr"] .col-sm-7,
[dir="ltr"] .col-sm-6,
[dir="ltr"] .col-sm-5,
[dir="ltr"] .col-sm-4,
[dir="ltr"] .col-sm-3,
[dir="ltr"] .col-sm-2,
[dir="ltr"] .col-sm-1,
[dir="ltr"] .col,
[dir="ltr"] .col-auto,
[dir="ltr"] .col-12,
[dir="ltr"] .col-11,
[dir="ltr"] .col-10,
[dir="ltr"] .col-9,
[dir="ltr"] .col-8,
[dir="ltr"] .col-7,
[dir="ltr"] .col-6,
[dir="ltr"] .col-5,
[dir="ltr"] .col-4,
[dir="ltr"] .col-3,
[dir="ltr"] .col-2,
[dir="ltr"] .col-1 {
  padding-right: 0.3rem;
  padding-left: 0.3rem;
}

[dir="ltr"] .container,
[dir="ltr"] .container-fluid,
[dir="ltr"] .container-xxl,
[dir="ltr"] .container-xl,
[dir="ltr"] .container-lg,
[dir="ltr"] .container-md,
[dir="ltr"] .container-sm {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 992px) {
  .col-lg-2 {
    max-width: 16.6666666667%;
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}

@media only screen and (max-width: 1440px) {
  .col-lll-2 {
    flex: 0 0 auto !important;
    width: 16.66666667% !important;
  }
}

@media only screen and (max-width: 960px) {
  .col-xs-6 {
    flex: 0 0 auto !important;
    width: 100% !important;
  }
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.dot {
  height: 20px;
  width: 20px;
  position: absolute;
  border-radius: 20px;
  transform: translate(6px, 51px);
  z-index: 100 !important;
}

.badge {
  padding: 0.2rem 0.5rem !important;
}
</style>
<style scoped>
.scrollable {
  overflow-y: auto;
  max-height: 80px;
  min-width: 125px;
  overflow-x: hidden;
  display: inline-block;
  width: 68%;
  max-height: 70px !important;
}
</style>
<style>
#carousel-caption ::-webkit-scrollbar {
  display: flex !important;
  width: 7px;
  background: transparent !important;
  min-height: 50px !important;
}

#carousel-caption ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 20px;
}

#carousel-caption ::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 20px;
}

#carousel-caption ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.dash-container {
  margin-top: -30px;
}
</style>
