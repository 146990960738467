<template>
    <b-row class="match-height dash-header-bread">
        <!--        <app-breadcrumb/>-->
        <!--        <b-col-->
        <!--                xl="7"-->
        <!--                lg="12"-->
        <!--                sm="12"-->
        <!--                cols="12"-->
        <!--                class="tab-bread"-->
        <!--        >-->
        <!--            <b-tabs>-->
        <!--                <b-tab-->
        <!--                        v-for="item in items"-->
        <!--                        :key="item.link"-->
        <!--                        :active="item.link === $route.fullPath"-->
        <!--                        :class="{'aktif':item.link === $route.fullPath}"-->
        <!--                        @click="goLink(item.link)"-->
        <!--                >-->
        <!--                    <template #title>-->
        <!--                        <p class="m-0 mr-50">-->
        <!--                            {{ item.text }}-->
        <!--                        </p>-->
        <!--                        <feather-icon-->
        <!--                                icon="XIcon"-->
        <!--                                class="xicon-color"-->
        <!--                                @click="deleteLink(item.link)"-->
        <!--                        />-->
        <!--                    </template>-->
        <!--                </b-tab>-->
        <!--            </b-tabs>-->
        <!--            &lt;!&ndash;            <b-breadcrumb>&ndash;&gt;-->
        <!--            &lt;!&ndash;                <b-breadcrumb-item v-for="item in items" :key="item.link">&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <b-badge pill variant="light-primary" @click="goLink(item.link)">&ndash;&gt;-->
        <!--            &lt;!&ndash;                        {{ item.text }}&ndash;&gt;-->
        <!--            &lt;!&ndash;                    </b-badge>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <feather-icon icon="XIcon" class="text-danger" @click="deleteLink(item.link)"/>&ndash;&gt;-->
        <!--            &lt;!&ndash;                </b-breadcrumb-item>&ndash;&gt;-->
        <!--            &lt;!&ndash;            </b-breadcrumb>&ndash;&gt;-->
        <!--        </b-col>-->
        <!--        <b-col-->
        <!--                xl="4"-->
        <!--                lg="11"-->
        <!--                sm="11"-->
        <!--                cols="11"-->
        <!--                class="content-header-right text-md-right d-md-block d-none date-input"-->
        <!--                style="z-index: 200"-->
        <!--        >-->
        <!--            <b-button-group class="mr-1">-->
        <!--                <b-button-->
        <!--                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                        variant="outline-primary"-->
        <!--                        @click="$store.dispatch('dateBack', 'auth', { root: true })"-->
        <!--                >-->
        <!--                    <feather-icon icon="SkipBackIcon"/>-->
        <!--                </b-button>-->
        <!--                <b-button-->
        <!--                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                        variant="outline-primary"-->
        <!--                        @click="$store.dispatch('dateNext', 'auth', { root: true })"-->
        <!--                >-->
        <!--                    <feather-icon icon="SkipForwardIcon"/>-->
        <!--                </b-button>-->
        <!--            </b-button-group>-->
        <!--            <b-input-group class="float-right flat-date">-->
        <!--                <flat-pickr-->
        <!--                        v-model="dateDefault"-->
        <!--                        class="form-control"-->
        <!--                />-->
        <!--            </b-input-group>-->
        <!--        </b-col>-->
        <!--        <b-col-->
        <!--                xl="1"-->
        <!--                lg="1"-->
        <!--                sm="1"-->
        <!--                cols="1"-->
        <!--                style="z-index: 100"-->
        <!--        >-->
        <!--            <b-dropdown-->
        <!--                    variant="secondary"-->
        <!--                    no-caret-->
        <!--                    toggle-class="p-0"-->
        <!--                    right-->
        <!--                    class="float-right date-side-dropdown"-->
        <!--            >-->
        <!--                <template #button-content>-->
        <!--                    <b-button-->
        <!--                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--                            variant="primary"-->
        <!--                            class="btn-icon"-->
        <!--                            :style="{ backgroundColor: $store.getters.headerBg + '!important' }"-->
        <!--                    >-->
        <!--                        <feather-icon icon="GridIcon"/>-->
        <!--                    </b-button>-->
        <!--                </template>-->
        <!--                <b-dropdown-item v-b-modal.rez-go>-->
        <!--                    <feather-icon-->
        <!--                            icon="SendIcon"-->
        <!--                            size="16"-->
        <!--                    />-->
        <!--                    <span class="align-middle ml-50">Rezervasyona Git</span>-->
        <!--                </b-dropdown-item>-->
        <!--                <b-dropdown-item v-b-modal.cari-gecmis>-->
        <!--                    <feather-icon-->
        <!--                            icon="SearchIcon"-->
        <!--                            size="16"-->
        <!--                    />-->
        <!--                    <span class="align-middle ml-50">Cari Konaklama Geçmişi</span>-->
        <!--                </b-dropdown-item>-->
        <!--                <b-dropdown-divider/>-->
        <!--                <b-dropdown-item @click="takvimUpdate">-->
        <!--                    <feather-icon-->
        <!--                            icon="RefreshCcwIcon"-->
        <!--                            size="16"-->
        <!--                    />-->
        <!--                    <span class="align-middle ml-50">Takvim Günü Güncelleştir</span>-->
        <!--                </b-dropdown-item>-->
        <!--                <b-dropdown-item @click="vardiyaClosed">-->
        <!--                    <feather-icon-->
        <!--                            icon="PowerIcon"-->
        <!--                            size="16"-->
        <!--                    />-->
        <!--                    <span class="align-middle ml-50">Vardiya Kapat</span>-->
        <!--                </b-dropdown-item>-->
        <!--            </b-dropdown>-->
        <!--        </b-col>-->
    </b-row>
</template>

<script>
import {
    BTab,
    BTabs,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBreadcrumb,
    BButton,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend, BBadge, BBreadcrumbItem,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
    components: {
        AppBreadcrumb,
        BTab,
        BTabs,
        BBreadcrumbItem,
        BBadge,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
        BButtonGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        flatPickr,
        BBreadcrumb,
        BButton,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            dateDefault: null,
        }
    },
    computed: {
        items() {
            return this.$store.getters.getBreadCrump
        },
    },
    watch: {
        dateDefault(newVal, oldVal) {
            this.$store.dispatch('selectDate', {dateDefault: newVal})
        },
    },
    methods: {
        goLink(link) {
            if (this.$route.fullPath !== link) {
                this.$router.push(link)
            }
        },
        deleteLink(adres) {
            if (this.$route.fullPath !== adres) {
                const index = store.state.breadcrump.findIndex(x => x.link === adres)
                if (index !== -1) {
                    store.state.breadcrump.splice(index, 1)
                }
            } else {
                const index = store.state.breadcrump.findIndex(x => x.link === adres)
                if (index !== -1) {
                    store.state.breadcrump.splice(index, 1)
                }
                const lastElement = store.state.breadcrump[store.state.breadcrump.length - 1]
                if (lastElement) {
                    this.$router.push(lastElement.link)
                } else {
                    this.$router.push('/')
                }
            }
        },
        vardiyaClosed() {
            this.$swal({
                title: 'Vardiyayı Kapatmak İstediğinize Eminmisiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet Kapat',
                cancelButtonText: 'Vazgeç',
                customClass: {
                    confirmButton: 'btn btn-bgInfo',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch('vardiyaClosed')
                        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                            const mesaj = this.$store.getters.notificationSettings(res)
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Başarılı',
                                    text: mesaj,
                                    icon: 'ThumbsUpIcon',
                                    variant: 'success',
                                },
                            }, {position})
                        })
                        .catch(err => {
                            const mesaj = this.$store.getters.notificationSettings(err)
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Hata!',
                                    text: mesaj,
                                    icon: 'ThumbsDownIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }
            })
        },
        takvimUpdate() {
            this.$store
                .dispatch('takvimGuncellestir')
                .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                    const mesaj = this.$store.getters.notificationSettings(res)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Başarılı',
                            text: mesaj,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                        },
                    }, {position})
                })
                .catch(e => {
                    const mesaj = this.$store.getters.notificationSettings(e)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsUpIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
    },
}
</script>

<style scoped>
.match-height {
    margin-top: -30px;
}

.flat-date {
    width: 25%;
}

.date-side-dropdown {
    margin-left: 80px;
}

.date-input {
    transform: translateX(80px);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
/*.nav-tabs {*/
/*    margin-bottom: 0;*/
/*}*/

/*.tab-bread .nav-tabs .nav-link {*/
/*    border: none;*/
/*    padding: 0.61rem 0.4rem;*/
/*    border-radius: 0;*/
/*}*/

.tab-bread {
    background-color: #69baed !important;
    border-radius: 5px !important;
    display: block;
    padding: 0;
}

.ic-sayfalar .nav-tabs .nav-link:after {
    display: none !important;
}

.dash-header-bread .nav-item {
    border-right: 2px solid white;
}

.dash-header-bread .nav-item p {
    color: white
}

.dash-header-bread .xicon-color {
    color: black
}

.dash-header-bread .active {
    background-color: #97afc4 !important;
    border-radius: 5px !important;
}

.nav-tabs {
    margin-bottom: 0 !important;
}

</style>
