<template>
  <div>
    <b-modal
        v-if="modal_durum"
        id="oda-durumu"
        ref="my-modal"
        :title="oda_index.odaKodu + ' Nolu Oda Durumu'"
        centered
        ok-only
        ok-variant="bgInfo"
        header-bg-variant="white"
        ok-title="Kaydet"
        cancel-variant="outline-secondary"
        size="sm"
        hide-footer
        @show="resetModal"
    >
      <form
          ref="form"
          @submit.stop.prevent="DurumGuncelle"
      >
        <b-row>
          <b-col cols="8">
            <b-form-select
                v-model="odaDurum"
                value-field="key"
                text-field="title"
            >
              <template #first>
                <b-form-select-option
                    :value="null"
                    disabled
                >seçim yapınız
                </b-form-select-option>
              </template>
              <b-form-select-option
                  v-for="(item, index) in odaDurumOptions"
                  :key="index"
                  :value="item.id"
              >
                {{ item.title }}
              </b-form-select-option>
            </b-form-select>
            <!-- <v-select id="vue-select" v-model="odaDurum" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="odaDurumOptions" label="title" /> -->
          </b-col>
          <b-col cols="4">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                type="submit"
            >
              Kaydet
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <b-modal
        v-if="modal_durum"
        id="acenta"
        ref="my-modal"
        no-close-on-backdrop
        :title="oda_index.odaKodu + ' Nolu Oda Acentası'"
        centered
        ok-only
        header-bg-variant="white"
        ok-variant="bgInfo"
        ok-title="Kaydet"
        cancel-variant="outline-secondary"
        size="sm"
        @show="resetModal"
        @ok="acentaHandleOk"
    >
      <template #modal-footer>
        <div class="row">
          <div class="col-12">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="bgDanger"
                class="mr-50"
                @click="acentaHide"
            >
              Kapat
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="bgSuccess"
                class="mr-50"
                @click="acentaGuncelle(0)"
            >
              Acentayı Temizle
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="bgInfo"
                class="mr-50"
                @click="acentaGuncelle"
            >
              Kaydet
            </b-button>
          </div>
        </div>
      </template>
      <form ref="form">
        <b-row cols="2">
          <b-col cols="12">
            <b-form-group
                label="Açıklama"
                label-for="aciklama"
            >
              <v-select
                  id="vue-select"
                  v-model="acenta"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="this.$store.state.auth.acentaList"
                  label="acenta"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormSelect,
  BFormSelectOption,
  BRow,
  BCol,
  BNavItem,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BFormTextarea,
    BNavItem,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: ['oda_index', 'modal_durum', 'oda_acenta'],
  data: () => ({
    odaDurumOptions: [
      {
        title: 'Müsait',
        id: '1'
      },
      {
        title: 'Kirli',
        id: '2'
      },
      {
        title: 'Arızalı',
        id: '3'
      },
      {
        title: 'Kullanım Dışı',
        id: '4'
      },
    ],
    odaDurum: [],
    acenta: [],
  }),
  watch: {
    oda_index(val) {
      switch (val.odaStatus) {
        case 1:
          this.odaDurum = {
            title: 'Müsait',
            id: '1'
          }
          break
        default:
          break
      }
    },
  },
  methods: {
    acentaHide() {
      this.$bvModal.hide('acenta')
    },
    resetModal() {
      this.odaDurum = []
      this.acenta = []
    },
    acentaHandleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.acentaGuncelle()
    },
    DurumGuncelle() {
      this.$store.dispatch('odaDurum', [
        this.oda_index.odaKodu,
        { ...this.odaDurum },
      ])
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Başarılı',
          text: 'Oda durumu güncellendi.',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    acentaGuncelle(isReset) {
      if (isReset == 0) {
        this.$store
            .dispatch('acenta', [
              this.oda_index.odaKodu,
              { ...this.acenta },
              'temizle',
            ])
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            })
      } else {
        this.$store
            .dispatch('acenta', [this.oda_index.odaKodu, { ...this.acenta }])
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            })
      }
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.modal-body {
  padding: 0.8rem 1.4rem;
  padding-top: 0 !important;
}

.modal .modal-header {
  padding-bottom: 0 !important;
}

.modal .modal-footer {
  padding: 1rem 0rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
